import React from "react"

import s from "./EmptyNotification.module.scss"

export default function EmptyNotification({ title, notification }) {
  return (
    <section>
      <h2 className={s.title}>{title}</h2>
      <p className={s.emptyNotification}>{notification}</p>
    </section>
  )
}

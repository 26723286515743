import { graphql } from "gatsby"
import React from "react"

import Img from "../../components/common/Img"
import RichText from "../../components/common/RichText"
import Hero, {
  colors,
  variations as heroVariations,
  titleColors,
} from "../../components/Hero/Hero"
import Layout from "../../components/Layout"
import MenuNav from "../../components/MenuNav/MenuNav"
import EmptyNotification from "../../components/ProductPage/EmptyNotification"
import NutritionInformation from "../../components/ProductPage/NutritionInformation/NutritionInformation"
import ProductAllergens from "../../components/ProductPage/ProductAllergens/ProductAllergens"
import SEO from "../../components/seo"
import {
  selectProductGroups,
  selectProductPageContent,
  selectTranslations,
} from "../../graphql/selectors"
import s from "./ProductPage.module.scss"

export default function ProductPage(props) {
  const { data, pageContext } = props
  const {
    backUrl,
    langCode,
    locale,
    localePath,
    product,
    pagePathsByLangCode,
  } = pageContext
  const translations = selectTranslations(data)
  const i18n = selectProductPageContent(data)
  const { allergensVisible, nutritionInformationVisible } = product

  const nutritionInformation =
    nutritionInformationVisible === false ? (
      <EmptyNotification
        notification={i18n.nutritionInformation.empty}
        title={i18n.nutritionInformation.title}
      />
    ) : (
      <NutritionInformation
        i18n={i18n.nutritionInformation}
        locale={locale}
        values={product.nutritionInformation}
      />
    )
  const allergens =
    allergensVisible === false ? (
      <EmptyNotification
        notification={i18n.allergens.emptyNotification}
        title={i18n.allergens.title}
      />
    ) : (
      <ProductAllergens allergens={product.allergens} i18n={i18n.allergens} />
    )

  return (
    <Layout
      className={s.productPage}
      i18n={{ ...translations, localePath }}
      langCode={langCode}
      pagePathsByLangCode={pagePathsByLangCode}
      productGroups={selectProductGroups(data)}
    >
      <SEO
        description={product.description}
        lang={langCode}
        title={product.name}
      />
      <MenuNav items={selectProductGroups(data)} />
      <Hero
        backUrl={backUrl}
        color={colors.crusta}
        titleColor={titleColors.bright}
        variation={heroVariations.product}
      />
      <div className={s.content}>
        <Img
          alt={product.name}
          className={s.productImage}
          loading="eager"
          objectFit="contain"
          objectPosition="center"
          placeholderHidden
          src={product.imageUrl}
        />
        <h1 className={s.title}>
          <RichText>{product.name}</RichText>
        </h1>
        <p
          className={s.description}
          dangerouslySetInnerHTML={{ __html: product.description }}
        />
        <div className={s.row}>
          {allergensVisible === false &&
          nutritionInformationVisible === false ? (
            <div className={s.leftCol}>
              <EmptyNotification
                notification={i18n.emptyIngredients}
                title={i18n.nutritionInformation.title}
              />
            </div>
          ) : (
            <>
              <div className={s.leftCol}>{nutritionInformation}</div>
              <div className={s.rightCol}>{allergens}</div>
            </>
          )}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ProductPage($locale: String) {
    ...LayoutFragment
    ...TranslationsFragment
    ...ProductPageFragment
  }
`

import React from "react"

import { formatNumber } from "../../../utils/strings"
import s from "./NutritionInformation.module.scss"

export default function NutritionInformation({ i18n, locale, values }) {
  return (
    <table className={s.table}>
      <thead>
        <tr>
          <th className={s.title}>{i18n.title}</th>
          <th className={s.subtitle}>{i18n.titlePerPortion}</th>
        </tr>
      </thead>
      <tbody>
        {values.map(({ name, unit, perPortion }) => (
          <tr key={name + "-" + unit}>
            <td>{name}</td>
            <td>
              {formatValue(perPortion)}&nbsp;{unit}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )

  function formatValue(value) {
    return formatNumber(value, locale)
  }
}

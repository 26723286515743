function formatNumber(value, locale) {
  const number = Number(value)

  if (isNaN(number)) return ""

  return new Intl.NumberFormat(locale, { maximumFractionDigits: 1 }).format(
    number
  )
}

function isPlainText(value) {
  return typeof value === "string" && !isImageUrl(value) && !isUrl(value)
}

function isImageUrl(value) {
  return (
    typeof value === "string" &&
    new RegExp(/.+(\.png|\.jpg|\.webp)$/gm).test(value)
  )
}

function isUrl(value) {
  return typeof value === "string" && new RegExp(/^(http|\/)/gm).test(value)
}

module.exports = {
  formatNumber,
  isPlainText,
  isImageUrl,
  isUrl,
}

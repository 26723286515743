import React from "react"

import s from "./ProductAllergens.module.scss"

export default function ProductAllergens({ i18n, allergens }) {
  return (
    <section>
      <h2 className={s.title}>{i18n.title}</h2>
      <ul>
        {allergens.map(({ icon, text }) => (
          <li className={s.allergen} key={text}>
            <img
              alt={text}
              className={s.icon}
              src={"/svg/allergens/" + icon + ".svg"}
            />
            <span>{text}</span>
          </li>
        ))}
      </ul>
    </section>
  )
}
